import React, { useLayoutEffect } from "react";
import queryString from "query-string";
import window from "global/window";
import SEO from "@src/components/SEO";

// Universal link page, just redirects to the parent app if user does not have native app
const IndexPage = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { c, st, school } = parsedLocation;

  useLayoutEffect(() => {
    if (c && st) {
      window.location.href = `https://home.classdojo.com/#/story/${st}/class/${c}`;
    } else if (school && st) {
      window.location.href = `https://home.classdojo.com/#/story/${st}/school/${school}`;
    } else {
      window.location.href = `https://home.classdojo.com/#/story`;
    }
  }, [c, st, school]);

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default IndexPage;
